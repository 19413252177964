import { allowComments, blockElements, dropElements } from "https://cdn.kernvalley.us/js/std-js/SanitizerConfigBase.js";
import { events } from "https://cdn.kernvalley.us/js/std-js/attributes.js";
export const allowAttributes = {
  'abbr': ['*'],
  // 'accept': ['*'],
  // 'accept-charset': ['*'],
  'accesskey': ['*'],
  'action': ['form'],
  // 'align': ['*'],
  // 'alink': ['*'],
  'allow': ['iframe'],
  'allowfullscreen': ['iframe'],
  'alt': ['img'],
  // 'anchor': ['*'],
  // 'archive': ['*'],
  'as': ['link'],
  'async': ['script'],
  'autocapitalize': ['form', 'input', 'textarea'],
  'autocomplete': ['input', 'select', 'textarea'],
  'autocorrect': ['form', 'input', 'textarea'],
  'autofocus': ['*'],
  'autopictureinpicture': ['video'],
  'autoplay': ['audio', 'video'],
  // 'axis': ['*'],
  // 'background': ['*'],
  // 'behavior': ['*'],
  // 'bgcolor': ['*'],
  'border': ['*'],
  // 'bordercolor': ['*'],
  // 'capture': ['*'],
  'cellpadding': ['*'],
  'cellspacing': ['*'],
  // 'challenge': ['*'],
  // 'char': ['*'],
  // 'charoff': ['*'],
  // 'charset': ['*'],
  'checked': ['input'],
  'cite': ['blockquote'],
  'class': ['*'],
  // 'classid': ['*'],
  // 'clear': ['*'],
  // 'code': ['*'],
  // 'codebase': ['*'],
  // 'codetype': ['*'],
  // 'color': ['*'],
  'cols': ['*'],
  'colspan': ['*'],
  // 'compact': ['*'],
  'content': ['meta'],
  'contenteditable': ['*'],
  'controls': ['audio', 'video'],
  // 'controlslist': ['*'],
  // 'conversiondestination': ['*'],
  // 'coords': ['*'],
  'crossorigin': ['script', 'link', 'img', 'audio', 'video'],
  'csp': ['iframe'],
  'data': ['*'],
  'datetime': ['time'],
  // 'declare': ['*'],
  'decoding': ['img'],
  'default': ['input'],
  'defer': ['script'],
  'dir': ['*'],
  // 'direction': ['*'],
  'dirname': ['*'],
  'disabled': ['button', 'input', 'fieldset'],
  'disablepictureinpicture': ['*'],
  'disableremoteplayback': ['*'],
  'download': ['a'],
  'draggable': ['*'],
  // 'elementtiming': ['*'],
  'enctype': ['form'],
  // 'end': ['*'],
  'enterkeyhint': ['input', 'button'],
  // 'event': ['*'],
  // 'exportparts': ['*'],
  // 'face': ['*'],
  'for': ['label', 'output'],
  // 'form': ['*'],
  // 'formaction': ['*'],
  // 'formenctype': ['*'],
  // 'formmethod': ['*'],
  // 'formnovalidate': ['*'],
  // 'formtarget': ['*'],
  // 'frame': ['*'],
  'frameborder': ['iframe'],
  // 'headers': ['*'],
  'height': ['img', 'iframe', 'video'],
  'hidden': ['*'],
  // 'high': ['*'],
  'href': ['a', 'link'],
  'hreflang': ['*'],
  // 'hreftranslate': ['*'],
  // 'hspace': ['*'],
  // 'http-equiv': ['*'],
  'id': ['*'],
  // 'imagesizes': ['*'],
  // 'imagesrcset': ['*'],
  'importance': ['*'],
  // 'impressiondata': ['*'],
  // 'impressionexpiry': ['*'],
  'incremental': ['*'],
  'inert': ['*'],
  'inputmode': ['*'],
  'integrity': ['link', 'script'],
  'invisible': ['*'],
  'is': ['*'],
  // 'ismap': ['*'],
  // 'keytype': ['*'],
  // 'kind': ['*'],
  'label': ['*'],
  'lang': ['*'],
  'language': ['*'],
  // 'latencyhint': ['*'],
  // 'leftmargin': ['*'],
  'link': ['*'],
  'list': ['input', 'textarea'],
  'loading': ['img', 'iframe'],
  // 'longdesc': ['*'],
  // 'loop': ['*'],
  // 'low': ['*'],
  // 'lowsrc': ['*'],
  // 'manifest': ['*'],
  // 'marginheight': ['*'],
  // 'marginwidth': ['*'],
  'max': ['input'],
  'maxlength': ['input', 'textarea'],
  // 'mayscript': ['*'],
  'media': ['link'],
  'method': ['form'],
  'min': ['input'],
  'minlength': ['input', 'textarea'],
  'multiple': ['input', 'select'],
  'muted': ['audio', 'video'],
  'name': ['*'],
  // 'nohref': ['*'],
  'nomodule': ['script'],
  'nonce': ['script', 'link'],
  'noresize': ['*'],
  // 'noshade': ['*'],
  'novalidate': ['*'],
  'nowrap': ['*'],
  'object': ['*'],
  'open': ['details', 'dialog'],
  // 'optimum': ['*'],
  'part': ['*'],
  'pattern': ['input', 'teaxarea'],
  // 'ping': ['*'],
  'placeholder': ['input', 'textarea'],
  'playsinline': ['*'],
  // 'policy': ['*'],
  'poster': ['video'],
  'preload': ['*'],
  // 'pseudo': ['*'],
  'readonly': ['input', 'textarea', 'select'],
  'referrerpolicy': ['script', 'link', 'img', 'video', 'source', 'iframe'],
  'rel': ['a', 'link'],
  // 'reportingorigin': ['*'],
  'required': ['input', 'textarea', 'select'],
  // 'resources': ['*'],
  'rev': ['*'],
  'reversed': ['*'],
  'role': ['*'],
  'rows': ['*'],
  'rowspan': ['*'],
  'rules': ['*'],
  'sandbox': ['iframe'],
  // 'scheme': ['*'],
  // 'scope': ['*'],
  // 'scopes': ['*'],
  // 'scrollamount': ['*'],
  // 'scrolldelay': ['*'],
  'scrolling': ['iframe'],
  // 'select': ['*'],
  'selected': ['option'],
  'shadowroot': ['template'],
  // 'shadowrootdelegatesfocus': ['*'],
  // 'shape': ['*'],
  'size': ['*'],
  'sizes': ['img'],
  'slot': ['*'],
  'span': ['*'],
  'spellcheck': ['*'],
  'src': ['img', 'script', 'source', 'audio', 'video'],
  // 'srcdoc': ['iframe'],
  'srclang': ['*'],
  'srcset': ['img'],
  // 'standby': ['*'],
  // 'start': ['*'],
  'step': ['input'],
  // 'style': ['*'],
  // 'summary': ['*'],
  'tabindex': ['*'],
  'target': ['a', 'form'],
  'text': ['*'],
  'title': ['*'],
  // 'topmargin': ['*'],
  // 'translate': ['*'],
  // 'truespeed': ['*'],
  // 'trusttoken': ['*'],
  'type': ['script', 'input'],
  // 'usemap': ['*'],
  // 'valign': ['*'],
  'value': ['input'],
  // 'valuetype': ['*'],
  // 'version': ['*'],
  // 'virtualkeyboardpolicy': ['*'],
  // 'vlink': ['*'],
  // 'vspace': ['*'],
  'webkitdirectory': ['*'],
  'width': ['img', 'iframe', 'video'],
  'wrap': ['*'] };


export const allowCustomElements = true;

export const allowElements = [
'a',
'abbr',
'acronym',
'address',
'area',
'article',
'aside',
'audio',
'b',
'bdi',
'bdo',
'big',
'blockquote',
'body',
'br',
'button',
'canvas',
'caption',
'center',
'cite',
'code',
'col',
'colgroup',
'datalist',
'dd',
'del',
'details',
'dialog',
'dfn',
'dir',
'div',
'dl',
'dt',
'em',
'fieldset',
'figcaption',
'figure',
'font',
'footer',
'form',
'h1',
'h2',
'h3',
'h4',
'h5',
'h6',
'head',
'header',
'hgroup',
'hr',
'html',
'i',
'img',
'input',
'ins',
'kbd',
'keygen',
'label',
'legend',
'li',
'link',
'listing',
'map',
'mark',
'menu',
'meta',
'meter',
'nav',
'nobr',
'ol',
'optgroup',
'option',
'output',
'p',
'picture',
'pre',
'progress',
'q',
'rb',
'rp',
'rt',
'rtc',
'ruby',
's',
'samp',
'section',
'select',
'slot',
'small',
'source',
'span',
'strike',
'strong',
'sub',
'summary',
'sup',
'svg',
'style',
'table',
'template',
'tbody',
'td',
'textarea',
'tfoot',
'th',
'thead',
'time',
'tr',
'track',
'tt',
'u',
'ul',
'var',
'video',
'wbr'];


export const dropAttributes = {
  'style': ['*'],
  ...Object.fromEntries(events.map((ev) => [ev, ['*']])) };


export const SanitizerConfig = {
  allowAttributes, allowComments, allowElements, allowCustomElements,
  blockElements, dropAttributes, dropElements };


export { allowComments, blockElements, dropElements };