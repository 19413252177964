import { TextEncoder } from "https://cdn.kernvalley.us/js/std-js/TextEncoder.js";
import { TextDecoder } from "https://cdn.kernvalley.us/js/std-js/TextDecoder.js";

if (!('TextEncoder' in globalThis)) {
  globalThis.TextEncoder = TextEncoder;
}

if (!('TextDecoder' in globalThis)) {
  globalThis.TextDecoder = TextDecoder;
}

if (!(globalThis.TextEncoder.prototype.encodeInto instanceof Function)) {
  globalThis.TextEncoder.prototype.encodeInto = function (...args) {
    return TextEncoder.prototype.encodeInto.apply(this, args);
  };
}