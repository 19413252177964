export const events = [
'onclick',
'onerror',
'onload',
'onloadstart',
'onloadend',
'onmouseenter',
'onmouseleave',
'onmousedown',
'onmouseup',
'onmousein',
'onmouseout',
'onpointerenter',
'onpointerleave',
'onpointerdown',
'onpointerup',
'onpointerin',
'onpointerout',
'onkeypress',
'onkeydown',
'onkeyup',
'onwheel',
'onscroll',
'onsubmit',
'onformdata',
'oncontextmenu',
'ondblclick',
'oncut',
'oncopy',
'onpaste',
'oninput',
'oninvalid',
'onchange',
'onbeforeinput',
'onfocus',
'onblur',
'onabort',
'onautocomplete',
'onautocompleteerror',
'oncancel',
'oncanplay',
'oncanplaythrough',
'onclose',
'oncuechange',
'ondrag',
'ondragend',
'ondragenter',
'ondragleave',
'ondragover',
'ondragstart',
'ondrop',
'ondurationchange',
'onemptied',
'onended',
'onloadeddata',
'onloadedmetadata',
'onmousemove',
'onmouseover',
'onmousewheel',
'onpause',
'onplay',
'onplaying',
'onprogress',
'onratechange',
'onreset',
'onresize',
'onseeked',
'onseeking',
'onselect',
'onshow',
'onsort',
'onstalled',
'onsuspend',
'ontimeupdate',
'ontoggle',
'onvolumechange',
'onwaiting'];


export const urls = [
'action',
'cite',
'formaction',
'href',
'ping',
'src'];